@import "src/fonts";

.manuscript-popover {
    &__popover {
        @include ebl-font; }
    &__header {
        font-weight: bold; }
    &__old-sigla {
        font-weight: normal;
        .type-abbreviation {
            display: none; } }
    &__body p {
        margin-bottom: .5rem; }
    &__provenance {
        font-variant: small-caps; }
    &__references {
        font-size: 80%; } }
