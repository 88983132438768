.signDisplay__LogogramInfo {
  min-width: 25%;
}

.CuneiformFonts__heading-old-babylonian-monumental {
  font-family: 'Old-Babylonian (Monumental)', 'Adobe Blank', Junicode, serif;
}

.CuneiformFonts__heading-old-babylonian-cursive {
  font-family: 'Old-Babylonian Cursive', 'Adobe Blank', Junicode, serif;
}

.CuneiformFonts__heading-hittite {
  font-family: Hittite, 'Adobe Blank', Junicode, serif;
}

.CuneiformFonts__heading-neo-babylonian {
  font-family: Neo-Babylonian, 'Adobe Blank', Junicode, serif;
}
@font-face {
  font-display: swap;
  font-family: 'Adobe Blank';
  src: url('fonts/AdobeBlank.otf.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'Old-Babylonian (Monumental)';
  src: url('fonts/SantakkuM.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Old-Babylonian Cursive';
  src: url('fonts/Santakku.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: Hittite;
  src: url('fonts/UllikummiA.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: Neo-Babylonian;
  src: url('fonts/Esagil.woff') format('woff');
}
