.dossier-records__item {
    margin-right: 8px; }

.dossier-records__item .dossier-name, .dossier-records__item__active .dossier-name {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-style: dotted; }

.reference-popover__popover {
    opacity: 0;
    transition: opacity 0.4s linear;

    &.show {
        transition: opacity 0.4s linear;
        opacity: 1; } }

.dossier-record p {
    margin-block-end: 0; }
