.pointer__hover:hover {
  cursor: pointer; }

.arabicGuideWord {
  text-indent: 2em;
  font-size: 70%; }

.supplementsAkkadianDictionaries {
  text-indent: 15px;
  margin-left: 5em;
  br {
    display: block;
    content: '';
    margin-top: 10px; } }

.dictionary-heading {
  margin-top: 1rem;
  margin-bottom: .5rem; }

.unicode-large {
  font-size: 120%; }

.fragmentlines-column {
  padding-bottom: 1em; }

.ipaTranscription .ipa-display {
  color: gray;
  font-size: 1.5rem; }
