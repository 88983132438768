.info {
      &__help {
            margin-left: 0.5rem; }
      &__header {
            display: flex;
            align-items: baseline; } }

ol.AfORegisterRecordsInFragment {
      padding-left: 15px;

      li small.text-black-50.ml-3 {
            display: none; } }
