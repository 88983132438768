.Word li,
.Word ul {
  display: inline;
  padding: 0;
}

.Word__forms::before {
  content: ', ';
}

.Word__form + .Word__form::before {
  content: ', ';
}

.Word__derivedFrom::before {
  content: '< ';
}

.AmplifiedMeanings__entry::before {
  content: ' ';
}

.Derived::before {
  content: '> ';
}

.Derived__group::after {
  content: '; ';
}

.Derived__entry + .Derived__entry::before {
  content: ', ';
}
