.cuneiform-converter-form {
    textarea.form-control {
        font-size: 150%;

        &.assurbanipal {
            font-family: Assurbanipal, Junicode, serif; }


        &.esagil {
            font-family: 'Neo-Babylonian', Junicode, serif; }


        &.santakku {
            font-family: 'Old-Babylonian Cursive', Junicode, serif; }


        &.santakkum {
            font-family: 'Old-Babylonian (Monumental)', Junicode, serif; }


        &.ullikummia {
            font-family: 'Hittite', Junicode, serif; } } }


