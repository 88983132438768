.ExternalResources {
  &__image {
    height: auto;
    max-height: 3.5em;
    max-width: 100%;
    margin: 0.2em; }

  &__items {
    padding: 0;
    list-style-position: inside; } }
