.Introduction__chart {
  max-width: 1429px;
  width: 80%; }

.Introduction__footer {
  margin: 1em 10%;
  text-align: center; }

.BAdWIcon__image {
  width: 350px; }

.LMUIcon__image {
  text-align: right;
  width: 200px; }

.HumboldtIcon__image {
  width: 200px;
  padding-bottom: 1em; }

.HumboldtIcon::after {
  content: '\a';
  white-space: pre; }

.Auth0Badge,
.Auth0Badge__image {
  height: 50px;
  width: 150px; }

.icon {
  &__large {
    font-size: 2.5em;
    margin-right: .2rem; }
  &__twitter {
    vertical-align: middle;
    color: #212529;

    &:hover {
      color: #212529; } } }

.eblInfo {
  vertical-align: middle;
  padding-right: 1em; }
