.text-view__section {
  padding-left: 2rem;
  margin-bottom: 2rem; }

.text-view__section-heading {
  text-indent: -2rem; }

.text-view__references {
  margin-top: .5rem; }

.text-view__reference-group {
  margin-bottom: .5em; }

.text-view__reference-group-title {
  font-weight: bold; }

.text-view__colophon-chapter {
  border-top: 1px solid black;
  margin-top: 1em;
  padding-top: 1em; }

.text-view__colophon-chapter-heading {
  font-size: 1rem;
  font-weight: bold; }

.text-view__colophon-siglum {
  font-size: 1rem;
  font-weight: normal; }

.text-view__markdown {
  text-align: justify;
  padding-right: 3em;
  br {
    display: block;
    content: '';
    margin-top: 10px; } }

.text-view__projects {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  .ExternalResources__image {
    max-height: 5em; } }
