.compositeSigns__compositeSigns {
  padding-left: 44px;
  text-indent: -44px;
}

.compositeSigns__compositeSigns__compositeSigns {
  max-width: 70%;
}

.compositeSigns__help {
  bottom: 0;
  margin-bottom: 0.3em;
  position: absolute;
  right: 4em;
}
