.Statistics {
  font-size: 1.5em;
  margin-top: 2em;
  margin-bottom: 1em;
}

.Statistics__row {
  margin-top: 1em;
  padding-left: 4em;
}

.Statistics__value {
  font-weight: bold;
}
