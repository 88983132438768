@mixin ebl-font {
  font-family: Assurbanipal, Junicode, serif; }

@font-face {
  font-display: swap;
  font-family: Junicode;
  src: url('JunicodeVF-Roman.woff2') format('woff'); }

@font-face {
  font-display: swap;
  font-family: Junicode;
  font-weight: bold;
  src: url('JunicodeVF-Bold.woff2') format('woff'); }

@font-face {
  font-display: swap;
  font-family: Junicode;
  font-style: italic;
  font-weight: bold;
  src: url('JunicodeVF-BoldItalic.woff2') format('woff'); }

@font-face {
  font-display: swap;
  font-family: Junicode;
  font-style: italic;
  src: url('JunicodeVF-Italic.woff2') format('woff'); }

@font-face {
  font-display: swap;
  font-family: Assurbanipal;
  font-style: normal;
  font-weight: normal;
  src: url('Assurbanipal.woff') format('woff'), url('Assurbanipal.woff2') format('woff2'), url('Assurbanipal.ttf') format('truetype');
  unicode-range: U+12000-123FF, U+12400-1247F, U+12480-1254F, U+100000-10FFFD; }
