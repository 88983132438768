.chapter-display {
    width: 100%;

    &__line-number {
        padding-right: 1em;
        font-size: 70%;
        white-space: nowrap;

        & ~ & {
            padding-left: 2em; }

        .chapter-display__variant {
            padding-left: 1em; } }

    &__line-number {
        text-align: right;

        &.left-align {
            text-align: left; } }

    &__old-line-numbers.hidden {
        display: none; }

    &__anchor {
        color: inherit; }

    &__intertext {
        color: #808080;
        font-size: 80%;
        padding: 1em 0;
        text-align: center;

        .type-abbreviation {
            display: none; } }

    &__line {
        &--is-second-line-of-parallelism {
            .chapter-display__line-number + td {
                padding-left: 0.75em; } }

        &--is-beginning-of-section {
            td {
                padding-top: 1em;

                vertical-align: top; } } }

    &__toggle {
        width: 1.5em;
        color: #007bff;
        cursor: pointer; }

    &__manuscripts {
        margin-left: 1em;
        margin-top: 0.5em;
        margin-bottom: 1.5em; }

    &__note {
        font-size: 90%;
        margin: 0.5em 2em 1em;
        text-align: justify;

        .type-abbreviation {
            display: none; } }

    &__parallels {
        color: #808080;
        font-size: 90%;
        list-style: none;
        margin: 0.5em 2em 1em;
        padding: 0; }

    &__manuscript {
        &--standard {
            font-weight: bolder; } }

    &__manuscript-siglum {
        font-size: 80%;
        padding-right: 0.5em;
        text-align: left; }

    &__manuscript-labels {
        font-size: 80%;
        text-align: left; }

    &__manuscript-paratext {
        font-size: 80%;
        color: #808080;
        padding-left: 1em; }

    &__manuscript-info-toggle {
        cursor: pointer;
        color: #007bff; }

    &__manuscript-notes {
        list-style: none;
        font-size: 80%;
        margin: 0;
        padding: 0; } }
