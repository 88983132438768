@import "src/fonts";

.signs__form {
  margin: 0 auto 2em;
  max-width: 45em; }

.mesZL__text {
  line-height: 180%; }

.mesZL__popover {
  min-width: 40%;
  @include ebl-font; }

.mesZL__popover pre {
  @include ebl-font; }

.mesZL__popover p {
  margin-bottom: 0;
  margin-top: 0.5em; }

.mesZL__popover hr {
  border-color: black;
  border-style: solid;
  border-width: 0 0 0.5px; }

.signs__sign {
  padding-left: 44px;
  text-indent: -44px; }

.signs__sign__name {
  font-style: normal;
  font-variant: all-small-caps; }

.signs__help__list {
  display: flex; }

.signs__help__list__label {
  min-width: 60px; }

.signs__help__popover {
  min-width: 600px; }

.signs__sign__cuneiform {
  font-size: 150%; }

.Neo-Assyrian {
  font-size: 150%;
  font-family: Assurbanipal, Junicode, serif;
  padding-left: 5px; }

.Neo-Babylonian {
  font-size: 150%;
  font-family: Neo-Babylonian,'Adobe Blank', Junicode, serif;
  padding-left: 5px; }

.beginning {
  background: linear-gradient(to left, grey 50%, blue 50%); }

.ending {
  background: linear-gradient(to right, grey 50%, blue 50%); }

.similar_text {
  padding-right: 5px; }
.secondary {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 0.3em 0;
  margin-left: 5px; }

.before {
  text-align: right; }
.after {
  text-align: left; }

span a:hover {
  text-decoration: underline;
  -webkit-text-fill-color: #0056b3; }
