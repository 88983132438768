.settings {
    margin-top: 6em;

    &__heading,
    &__footer {
        text-align: right;
        font-size: 1.1em;
        cursor: pointer; }

    &__section {
        margin-bottom: 2.5em;
        padding-left: 2.5em; }

    &__subheading {
        font-size: 1.2em; }

    &__switch {
        margin-left: 1em; }

    &__languages {
        list-style: none;
        margin: 0;
        padding: 0; }

    &__language {
        &--active {
            text-decoration: underline; }

        cursor: pointer;
        margin-left: 1em; }

    &__translators {
        font-size: 80%; } }
