.Photo__copyright {
  margin-top: 1em;
  text-align: center;
}

.Photo__copyright small {
  color: gray;
  font-size: 0.9em;
}

.photo-container {
  position: relative;
}

.image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
