.sign-images__sign-image {
  min-width: 110px;
  max-height: 110px;
}

.figure-caption .mesopotamian-date-display {
  color: black;
  font-size: 90%;
}

.figure-caption .provenance {
  color: black;
}
