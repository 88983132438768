.CuneiformFragment {
  &__content {
    padding-bottom: 1em;
    padding-top: 1em;

    p {
      margin-bottom: 0.5rem; }

    section {
      padding-bottom: 1rem; } }

  &__markup-section {
    text-align: justify;

    .type-abbreviation {
      display: none; } }

  &__info section {
    margin-bottom: 1rem; }

  &__tabs-hidden {
    display: none; } }
