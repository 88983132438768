.Word--suggestion {
  color: red;
}

.Word--with-alignment {
  font-weight: bolder;
}

.Word__alignment {
  font-size: 75%;
  font-style: italic;
  font-weight: normal;
  margin-left: 0.33em;
  vertical-align: sub;
}
