.research-projects {
  .list-group {
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden; }

  .list-group-item {
    border: 1px solid #ddd !important;
    margin-bottom: 10px;
    border-radius: 0; }

  .project-list__image {
    max-width: 25%;
    height: auto;
    border-radius: 5px; }

  .project-name {
    font-size: 1em;
    padding-left: 2em; } }
