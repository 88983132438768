.date_converter {
    padding-top: 50px;
    padding-bottom: 20px; }

.section-row {
    align-items: stretch;
    border-bottom: 1px grey solid;
    padding-top: 10px;

    .even {
        .section-title {} }

    .odd {
        .section-title {} } }

.section-row:last-child {
    border-bottom: none; }

.section-title {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 80%;
    letter-spacing: 1px; }

.section-fields {
    padding-left: 15px; }

.active-field {
    background-color: #f9ffcf; }

.copy-button {
    position: absolute;
    bottom: 100px; }

.dropdown-toggle::after {
    display: none !important; }
