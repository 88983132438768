.list-of-manuscripts {
    &__header {
        border-top: 1px solid black; }

    &__column-heading {
        font-style: italic;
        font-weight: bold; }

    &__provenance-heading {
        border-top: 1px solid black;
        font-weight: bold;
        padding-top: 0.8em; }

    &__siglum-heading {
        font-weight: normal;
        padding-right: 1em;
        vertical-align: top; }

    &__extant-lines,
    &__museum-numbers {
        vertical-align: top; }

    &__references,
    &__uncertain-fragments {
        list-style-position: inside;
        margin-bottom: 1em;
        padding-left: 0.1em; }

    &__help {
        margin-left: 0.5rem; } }
