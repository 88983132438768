.Header {
  &__badw-logo {
    height: 1.7em; }

  &__lmu-logo {
    height: 2em;
    margin-bottom: 0.2em; }

  &__logo-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    min-width: 125px; }

  &__title {
    font-size: 1em;
    display: flex;
    align-items: center;

    &-main {
      display: inline-block;
      margin-right: 0.5em;
      text-align: right;
      vertical-align: middle;

      &__caps {
        font-variant-caps: all-small-caps;
        font-size: 1em; } }

    &-abbreviation {
      color: white;
      font-size: 3em;
      padding: 0 0.1em;
      background-color: #0077be;
      vertical-align: middle;
      margin-right: 0.2em;

      &__caps {
        font-variant-caps: all-small-caps;
        font-size: 1em; } } } }

#navbar-container {
  width: 100%; }

#menu-lines {
  padding: 0 40px; }

.Header__info-banner {
  padding-left: 0;
  padding-right: 0;
  max-width: 1200px; }
