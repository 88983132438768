.main {
  align-items: stretch;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  padding-bottom: 1em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  min-height: 90vh;

  &--wide {
    max-width: initial; }

  &__header {
    display: flex;
    flex-flow: wrap;
    margin-bottom: 1em; }

  &__breadcrumbs {
    flex: 0 0 100%; }

  &__heading {
    flex: auto; }

  &__toolbar {
    align-content: flex-start; }

  &__content {
    flex: auto; }

  &__sidebar {
    border-radius: 0.25rem;
    background: #417DD2;
    background-blend-mode: lighten;
    background-image: url(https://www.ebl.lmu.de/api/images/Babel_Project_01_cropped.svg);
    background-position: right -12em bottom;
    background-repeat: no-repeat;
    background-size: 20em;
    color: #FFFFFF;
    flex: 1 0 content;
    margin-left: 1em;
    max-height: 100vh;
    padding: 1em 1em 23em 1em;
    position: sticky;
    top: 0; } }
