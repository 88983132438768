.main-footer {
    max-width: 1200px;
    padding-left: 1.5em;
    padding-right: 1.5em;
    margin-left: auto;
    margin-right: auto;

    ol.breadcrumb {
        & li a {
            padding: 0;
            display: inline; }
        & li+li {
            padding-left: .5rem;
            &::before {
                display: inline-block;
                padding-right: .5rem;
                color: #6c757d;
                content: "|"; } } } }
