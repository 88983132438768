.references-help {
  &__definitions {
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 110px; }

  &__abbreviation {
    float: left;
    margin: 0;
    padding: 0;
    width: 20%; }

  &__type {
    float: left;
    margin: 0;
    padding: 0;
    width: 80%; } }
