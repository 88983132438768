@import "src/fonts";

.word-info {
    &__header {
        @include ebl-font;
        font-weight: bold; }

    &__words {
        @include ebl-font;
        list-style: none;
        margin: 0;
        padding: 0;

        &.row {
            padding-bottom: .2em !important; } }

    &__lemma {
        font-style: italic; }

    &__trigger {
        cursor: pointer; }

    &__variant-alignment-indicator {
        font-style: normal; }

    &__aligned-tokens {
        padding: .5em 0 0;

        .row {
            .col, .col-auto {
                padding: 0; } } }

    &__aligned-word {
        white-space: nowrap; }

    &__variant {
        &--heading {
            padding: .5em 0 0 .5em; }

        &--guideword {
            float: left;

            > span {
                font-style: italic; } } }

    &__sigla {
        padding-left: .5em !important; } }
