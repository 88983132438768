.fragment-lines-with-lemma {
    &__fragment-number {
        vertical-align: top;
        font-weight: normal;
        padding: 1em .5em 0 0;

        & ~ td {
            padding-top: 1em; } }
    &__line-number {
        vertical-align: top;
        text-align: right;
        padding-right: .5rem; } }
