.Details {
  list-style: none;
  padding: 0;
}

.Details__item {
  margin-bottom: 0.4em;
}

.Details__item--provenance {
  padding-left: 1em;
  list-style-position: outside;
  list-style-type: disc;
}

.Details__item--extra-margin {
  margin-bottom: 1em;
}

.Details-joins {
  padding-left: 0.8em;
  text-indent: -0.8em;
}

.Details-joins__list {
  display: inline;
  padding: 0;
}

.Details-joins__join {
  display: inline;
}
