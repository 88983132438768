td.chronology-display {
    vertical-align: middle;
    padding: 0px;

    &__section {
        padding-bottom: 0px; } }

tr.kings:hover {
    background-color: lightgrey; }

tr:not(:first-child) td.chronology-display__section {
    padding-top: 30px; }

